import React from "react";
import Footer from "../Footer";
import Header from "../Header";
import * as styles from "./style.module.scss";

const Layout = ({ children }) => {

	/**
	 * gatsby-browser.jsの「onClientEntry」にてAdobe Fontsのスクリプトを読み込んでいたが、エラーが発生したためこちらに移動した。
	 *
	 * 「gatsby-plugin-web-font-loader」を使用して読み込むのが正しいらしいが、検証する時間がなかったのでひとまずはここでの読み込みにて対応した。
	 * 「gatsby-plugin-web-font-loader」は未検証です。
	 *
	 * @link https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#onClientEntry
	 * @link https://www.gatsbyjs.com/plugins/gatsby-plugin-web-font-loader/
	 */

	if (typeof window !== "undefined") {
		const script = document.createElement('script');

		script.innerHTML = `
		(function(d) {
			var config = {
				kitId: 'qjl3kho',
				scriptTimeout: 3000,
				async: true
			},
			h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
		})(document);`;

		document.head.appendChild(script);
	}

	return (
		<div className={styles.layout}>
			<Header />
			<main className={styles.body}>
				{children}
			</main>
			<Footer />
		</div>
	);
};

export default Layout;
