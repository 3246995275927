import React from "react";
import Layout from "./src/components/Layout";
import "./src/styles/style.scss";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export const wrapRootElement = ({ element }) => {
	const RECAPTCHA_SITE_KEY = process.env.RECAPTCHA_SITE_KEY || "";
	return (
		<GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
			{element}
		</GoogleReCaptchaProvider>
	);
};

export const wrapPageElement = ({ element }) => {
	return <Layout>{element}</Layout>
};
